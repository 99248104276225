import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import copy from 'copy'
// import { useFetchData } from 'hooks'
import { getAttrSelection, getLayeredViewAttributesPreview, getActiveView, getLayers, getActiveProjectConsumer, getBackgroundImage } from 'select'
import { _getViewData } from 'select/data'
// import MasterDetailLayout from 'scenes/layout/MasterDetailLayout'
// import Dashboard from 'scenes/views/Dashboard'
import ConsumerViewSelect from './ConsumerViewSelect'
// import Loader from 'scenes/app/Loader'
// import { _getViewData } from 'select/data'

import { setActiveViewId, setBackgroundImage } from 'actions'

import './PreviewWindow.css'

const RendererLayers = connect(
  state => ({
    project: getActiveProjectConsumer(state),
    layers: getLayers(state),
    view: getActiveView(state),
    layeredViewAttributes: getLayeredViewAttributesPreview(state),
    attrSelection: getAttrSelection(state),
    savedBackgroundImage: getBackgroundImage(state),
  }),
  dispatch => bindActionCreators({
    setActiveViewId,
    setBackgroundImage,
  }, dispatch)
)(({
  layeredViewAttributes,
  attrSelection,
  layers,
  project,
  view,
  savedBackgroundImage,
  setActiveViewId,
  setBackgroundImage
}) => {

  const [bImage, setBImage] = useState()

  useEffect(() => {
    if (view) {
      setActiveViewId(view.id)
      // console.log("erere")
    }
    setBImage(savedBackgroundImage)
    // For debug
    // const distinctionPersonalizationCoverLarge = "https://fineandcandy.gaspardbruno.com/wp-content/uploads/2018/05/Laguna.jpg"

    if (typeof distinctionPersonalizationCoverLarge != "undefined") {
      // eslint-disable-next-line
      setBImage(distinctionPersonalizationCoverLarge)
      // eslint-disable-next-line
      setBackgroundImage(distinctionPersonalizationCoverLarge)
    }
  }, [view, savedBackgroundImage])

  const baseBrickId = (layeredViewAttributes && layeredViewAttributes[0] && layeredViewAttributes[0].brickId) ? layeredViewAttributes[0].brickId : -1



  // Need to be cleaned
  let atrDefautlSelected = layeredViewAttributes[0] && attrSelection.find(([attrId, attrType, textValue, variantName]) => layeredViewAttributes[0].attributableId.toString() === attrId && layeredViewAttributes[0].attributeType === attrType)
  let viewAtrDefautlSelected = atrDefautlSelected && layeredViewAttributes && atrDefautlSelected[0] && layeredViewAttributes.find(({attributableId}) => atrDefautlSelected[0] === attributableId.toString())
  let viewAtrImageDefautlSelected = viewAtrDefautlSelected && viewAtrDefautlSelected.image

  return !project ?
      null
    :
    <div><div  className="RendererScreen" >
      <div id="renderer" className={ project.attributes.hasFixedSize ? "preview" : "preview unset"}
          style={{
            background: `${ project.attributes.backgroundColor }`,
            width: `${ project.attributes.renderWidth }`,
            height: `${ project.attributes.renderHeight }`,
          }}>
       {(bImage && !viewAtrImageDefautlSelected) &&
          <React.Fragment key={-1} >
            <img
              src={bImage}
              className="backgroundImage"
            />

            {false &&
              <div
                style={{
                  backgroundImage: `url(${ bImage })`
                }}
                className="backgroundImage"
              ></div>
            }
          </React.Fragment>
        }
        {(bImage && viewAtrImageDefautlSelected) &&
          <React.Fragment key={-1} >
            <img
              src={viewAtrImageDefautlSelected}
              className="backgroundImage"
            />

            {false &&
              <div
                style={{
                  backgroundImage: `url(${ viewAtrImageDefautlSelected })`
                }}
                className="backgroundImage"
              ></div>
            }
          </React.Fragment>
        }
        {layeredViewAttributes && layeredViewAttributes.map(({ layerId, brickId, attributableId, attributeType, id, image, blendingMode, blendingValue, typefaceName, typefaceSize, typefaceColor, typefaceTextAlign, typefaceTextTransform, typefaceX, typefaceY, typefaceOrientation, typefaceCharacterLimit, typefaceRotate, typefaceShadow, symbolSize, symbolColor, symbolX, symbolY }, j) =>
            <React.Fragment key={j}
              >
              { attrSelection.find(([attrId, attrType, textValue, variantName]) => attributableId.toString() === attrId && attributeType === attrType) &&
                <div >
                  { typefaceTextAlign === 'center' &&
                    <div className={baseBrickId === brickId ? "layer text layer-relative" : "layer text"}
                      style={{
                        display: 'inline-block',
                        fontFamily: typefaceName,
                        fontSize: typefaceSize,
                        textAlign: typefaceTextAlign,
                        textTransform: typefaceTextTransform,
                        textShadow: typefaceShadow,
                        transform: `rotate(${ typefaceRotate }deg) translate(-50%, -50%)`,
                        color: typefaceColor,
                        left: '50%',
                        marginLeft: typefaceX,
                        top: "50%",
                        marginTop: typefaceY,
                        display: layers.find(({layerId}) => layerId === id) ? '' : 'none',
                        width: 'unset',
                        height: '100%',
                        transformOrigin: 'center',
                      }}
                    >
                    {(typefaceCharacterLimit && typefaceCharacterLimit > 0 && attrSelection.find(([attrId, attrType, textValue, variantName]) => attributableId.toString() === attrId && attributeType === attrType)[2]) ? attrSelection.find(([attrId, attrType, textValue, variantName]) => attributableId.toString() === attrId && attributeType === attrType)[2].substring(0,typefaceCharacterLimit) : attrSelection.find(([attrId, attrType, textValue, variantName]) => attributableId.toString() === attrId && attributeType === attrType)[2]}</div>
                  }
                  { typefaceTextAlign === 'right' &&
                    <div className={baseBrickId === brickId ? "layer text layer-relative" : "layer text"}
                      style={{
                        display: 'inline-block',
                        fontFamily: typefaceName,
                        fontSize: typefaceSize,
                        textAlign: typefaceTextAlign,
                        textTransform: typefaceTextTransform,
                        textShadow: typefaceShadow,
                        transform: `rotate(${ typefaceRotate }deg) translate(-50%, 0)`,
                        color: typefaceColor,
                        right: '0',
                        marginRight: typefaceX,
                        top: "50%",
                        marginTop: typefaceY,
                        display: layers.find(({layerId}) => layerId === id) ? '' : 'none',
                        width: 'unset',
                        height: '100%',
                        transformOrigin: 'right',
                      }}
                    >
                    {(typefaceCharacterLimit && typefaceCharacterLimit > 0 && attrSelection.find(([attrId, attrType, textValue, variantName]) => attributableId.toString() === attrId && attributeType === attrType)[2]) ? attrSelection.find(([attrId, attrType, textValue, variantName]) => attributableId.toString() === attrId && attributeType === attrType)[2].substring(0,typefaceCharacterLimit) : attrSelection.find(([attrId, attrType, textValue, variantName]) => attributableId.toString() === attrId && attributeType === attrType)[2]}</div>
                  }
                  { typefaceTextAlign === 'left' &&
                    <div className={baseBrickId === brickId ? "layer text layer-relative" : "layer text"}
                      style={{
                        display: 'inline-block',
                        fontFamily: typefaceName,
                        fontSize: typefaceSize,
                        textAlign: typefaceTextAlign,
                        textTransform: typefaceTextTransform,
                        textShadow: typefaceShadow,
                        transform: `rotate(${ typefaceRotate }deg) translate(-50%, -50%)`,
                        color: typefaceColor,
                        left: '50%',
                        top: '50%',
                        marginLeft: typefaceX,
                        marginTop: typefaceY,
                        display: attrSelection.find(([attrId, attrType, textValue, variantName]) => attributableId.toString() === attrId && attributeType === attrType) ? '' : 'none',
                        width: 'unset',
                        transformOrigin: 'left',
                      }}
                    >
                    {(typefaceCharacterLimit && typefaceCharacterLimit > 0 && attrSelection.find(([attrId, attrType, textValue, variantName]) => attributableId.toString() === attrId && attributeType === attrType)[2]) ? attrSelection.find(([attrId, attrType, textValue, variantName]) => attributableId.toString() === attrId && attributeType === attrType)[2].substring(0,typefaceCharacterLimit) : attrSelection.find(([attrId, attrType, textValue, variantName]) => attributableId.toString() === attrId && attributeType === attrType)[2]}</div>
                  }
                </div>
              }
              { (image && project.attributes.hasFixedSize && blendingMode != 'none') &&

                  <div
                      className={baseBrickId === brickId ? "layer blend layer-relative" : "layer blend" }
                      style={{
                      maskImage: `url(${ image })`,
                      WebkitMaskBoxImage: `url(${ image })`,
                      mixBlendMode: blendingMode,
                      backgroundColor: `${ blendingValue }`,
                      color: symbolColor,
                      left: symbolX,
                      top: symbolY,
                      display: layers.find(({layerId}) => layerId === id) ? '' : 'none',
                    }}
                  >

                    <img
                      src={image}
                      className="image"
                      style={{
                        width: symbolSize ? symbolSize : '100%',
                        color: symbolColor,
                        left: symbolX,
                        top: symbolY,
                        opacity: blendingMode !== 'none' ? 0 : 1,
                        display: layers.find(({layerId}) => layerId === id) ? '' : 'none',
                      }}
                    />
                </div>

              }
              { (image && project.attributes.hasFixedSize && blendingMode === 'none') &&
                <div className="layer image"
                    style={{
                      backgroundImage: `url(${ image })`,
                      width: symbolSize,
                      color: symbolColor,
                      left: symbolX,
                      top: symbolY,
                      display: layers.find(({layerId}) => layerId === id) ? '' : 'none',

                    }}
                    >

                </div>
              }
              { (image && !project.attributes.hasFixedSize && blendingValue != 'transparent') &&

                  <div
                      className={baseBrickId === brickId ? "layer blend layer-relative" : "layer blend" }
                      style={{
                      WebkitMaskBoxImage: `url(${ image })`,
                      maskImage: `url(${ image })`,
                      mixBlendMode: blendingMode,
                      backgroundColor: `${ blendingValue }`,
                      color: symbolColor,
                      left: symbolX,
                      top: symbolY,
                      display: layers.find(({layerId}) => layerId === id) ? '' : 'none',
                    }}
                  >

                    <img
                      src={image}
                      className="image"
                      style={{
                        width: symbolSize ? symbolSize : '100%',
                        color: symbolColor,
                        left: symbolX,
                        top: symbolY,
                        opacity: blendingMode !== 'none' ? 0 : 1,
                        display: layers.find(({layerId}) => layerId === id) ? '' : 'none',
                      }}
                    />
                </div>

              }
              { (image && !project.attributes.hasFixedSize && blendingValue === 'transparent') &&

                  <div
                      className={baseBrickId === brickId ? "layer blend layer-relative" : "layer blend" }
                      style={{
                      WebkitMaskBoxImage: `url(${ image })`,
                      maskImage: `url(${ image })`,
                      mixBlendMode: blendingMode,
                      boxShadow: `inset 0 ${ String(parseInt(symbolSize.replace("px", "") * 2) + "px") } 3px rgba(255, 255, 255, 0.3), inset 0 -2px 3px rgba(0,0,0,0.3), 0 9px 1px rgba(255,255,255,0.9)`,
                      WebkitBoxShadow: `inset 0 ${ String(parseInt(symbolSize.replace("px", "") * 2) + "px") } 3px rgba(255, 255, 255, 0.3), inset 0 -2px 3px rgba(0,0,0,0.3), 0 9px 1px rgba(255,255,255,0.9)`,
                      backgroundColor: `${ symbolColor }`,
                      color: symbolColor,
                      left: symbolX,
                      top: symbolY,
                      display: layers.find(({layerId}) => layerId === id) ? '' : 'none',
                    }}
                  >

                    <img
                      src={image}
                      className="image"
                      style={{
                        width: symbolSize ? symbolSize : '100%',
                        color: symbolColor,
                        left: symbolX,
                        top: symbolY,
                        opacity: 0,
                        display: layers.find(({layerId}) => layerId === id) ? '' : 'none',
                      }}
                    />
                </div>

              }
            </React.Fragment>
          )
        }
      </div>

    <ConsumerViewSelect />
  </div>

  { false && layers && <div className="specs">
    <h2>CURRENTLY SELECTED OPTIONS:</h2>
    <div>{ layers.map(({ name, brickName, swatch }, j) =>
      <div key={j}>
        BRICK: {brickName}
        <br />
        CHOSEN ATTRIBUTE: {name}
        <br />
        SWATCH: {swatch}
      </div>) }
    </div>
    <hr />
    <h3>AVAILABLE LAYERS BASED ON SELECTED OPTIONS:</h3>
    <div>{ layers.map(({ name, image, visible, order, blendingMode, blendingValue }, j) =>
      visible && <div key={j}>
        LAYER #{j} (order: { order })
        <br />
        NAME: {name}
        <br />
        IMAGE URL: {image}
        <br />
        blendingMode: { blendingMode }, blendingValue: { blendingValue }
      </div>) }
    </div>
  </div> }
  </div>
})

const RendererBody = connect(
  state => ({ views: _getViewData(state) })
)(({ views }) =>
  <div>
    {
      <RendererLayers />
    }
  </div>)

const PreviewWindow = () =>
  <RendererBody />

export default PreviewWindow
