import { fork, takeLatest, call, put, select } from 'redux-saga/effects'

import { getActiveProject, getFCCost, getPreview, getBackgroundImage } from 'select'

import { save } from 'api/preview'

import { getSpecheetsFromUrl, getSpecheetsFromPreview } from 'api/specsheet'

export const watchSetUrl = function* () {
  yield takeLatest(['setUrl'],
    function* ({ url }) {
      const response = yield call(getSpecheetsFromUrl, url)
      yield put({ type: 'setData', entity: 'view', response })
    }
  )
}

export const watchSetPreview = function* () {
  yield takeLatest(['setPreview'],
    function* ({ preview }) {
      const response = yield call(getSpecheetsFromPreview, preview)
      yield put({ type: 'setState', entity: null, response })
    }
  )
}

export const watchSetBackgroundImage = function* () {
  yield takeLatest(['setBackgroundImage'],
    function* (backgroundImage) {
      const response = {
        'data': backgroundImage,
      }
      yield put({ type: 'setBackground', entity: 'backgroundImage', response })
    }
  )
}

export const watchSetLinkedAttributeValue = function* () {
  yield takeLatest(['setLinkedAttributeValue'],
    function* (viewAttributeId, blendingValue) {
      const response = {
        'data':
          viewAttributeId,
          blendingValue,
      }
      yield put({ type: 'setViewAttributeBlendingValue', entity: 'viewAttribute', id: viewAttributeId, response })
    }
  )
}

export const watchSetSelectors = function* () {
  yield takeLatest(['setSelectors'],
    function* ({ selectors }) {
      yield put({ type: 'setSelectors', entity: 'selectors', selectors })
    }
  )
}

export const watchSavePreview = function* () {
  yield takeLatest(['savePreview'],
    function* ({ preview }) {

      const response = yield call(save, preview, preview.project_id)
      const { data } = response
      if (data) {
        yield put({ type: 'setData', entity: 'preview', response, requestDone: 'previewSave' })

        const cost = yield select(getFCCost)
        const preview = yield select(getPreview)

        //Preview link
        if (typeof distinctionPreviewUrl != "undefined") {
           // eslint-disable-next-line
           distinctionPreview = preview
         }

        //custom add to bag
        if (typeof preDistinctionAddToBag != "undefined") {
           // eslint-disable-next-line
           preDistinctionAddToBag(cost, preview)
         }

         if (typeof DistinctionAddToBag != "undefined") {
           // eslint-disable-next-line
           DistinctionAddToBag(cost, preview)
         }
         if (typeof postDistinctionAddToBag != "undefined") {
           // eslint-disable-next-line
           postDistinctionAddToBag(cost, preview)
         }
      } else {
        yield put({ type: 'addMessage', messageType: 'error', key: 'previewSave', requestDone: 'previewSave' })
      }
    }
  )
}


export default function* () {
  yield fork(watchSetUrl)
  yield fork(watchSetPreview)
  yield fork(watchSavePreview)
  yield fork(watchSetBackgroundImage)
  yield fork(watchSetLinkedAttributeValue)
}
