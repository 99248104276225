
export const getSpecheetsFromUrl = async (url) => {
  const response = await fetch(
    url,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return await response.json()
}

export const getSpecheetsFromPreview = async (preview) => {
  const url = `${process.env.REACT_APP_BASE_URL}/v1/previews?slug=${preview}`
  const response = await fetch(
    url,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return await response.json()
}
