export default {
  messages: {
    info: {
      welcome: 'Welcome to Distinction',
    },
    error: {
      signup: 'There was a problem signing up',
      login: 'Invalid email / password',
    },
    project: {
      projectSaved: "Project saved",
      projectDuplicated: "Project duplicated",
    },
    brick: {
      brickSaved: 'Brick saved',
    },
    attribute: {
      customizationAttributeSaved: 'Attribute saved',
      symboleAttributeSaved: 'Attribute saved',
      textAttributeSaved: 'Attribute saved',
      attributeDuplicated: 'Attribute Duplicated',
    },
    viewAttribute: {
      viewAttributeSaved: 'View Attribute saved',
    },
    organizationDetails: {
      organizationDetailsSaved: 'Organization Details saved',
      organizationDetailsPublished: 'Organization Published - Cache refresh ongoing',
    },
    resetPassword: {
      resetPasswordRequested: 'You should receive a email shortly',
      resetPasswordDone: 'Your password has been reset',
    },
  },
  app: { // App scene
    titles: {
      projects: 'Projects',
    },
    edit: 'Edit',
    cancel: 'Cancel',
    close: 'Close',
    save: 'Save',
    duplicate: 'Duplicate',
  },
  // project scene
  project: {
    setup: 'Setup new project',
    edit: 'Edit your project',
    previews: 'Previews: ',
    modal_title: 'Do you really want to delete this project?',
    labels: {
      name: 'Project name',
      renderHeight: 'Render height',
      renderWidth: 'Render width',
      hasFixedSize: 'Has fixed size?',
      hasBackground: 'Has background?',
      hasLoader: 'Has loader?',
      addToBagClass: 'Custom add to bag class',
      backgroundColor: 'Background color',
      generic: 'Generic product?',
      genericCategory: 'Generic category id',
      ressourceLink: 'Link to this project ressource',
      externalProductReference: 'Link this project to a product',
    },
    placeholders: {
      name: 'Project name',
      renderHeight: 'Render height (px)',
      renderWidth: 'Render width (px)',
      hasFixedSize: 'As fixed size?',
      hasBackground: 'As background?',
      hasLoader: 'Has loader?',
      addToBagClass: 'Custom add to bag class',
      backgroundColor: 'Background color',
      generic: 'Generic product?',
      genericCategory: '123',
      ressourceLink: '',
      externalProductReference: 'Link this project to a product',
    },
    template: {
      name: 'New Project',
      renderHeight: 400,
      renderWidth: 600,
      hasFixedSize: 'As fixed size?',
      hasBackground: false,
      hasLoader: 'Has loader?',
      addToBagClass: 'Custom add to bag class',
      backgroundColor: 'transparent',
      generic: false,
      genericCategory: '123',
      ressourceLink: '',
      externalProductReference: '',
    },
  },
  // view scene
  view: {
    title: 'View',
    add: 'Add view',
    edit: 'Edit',
    modal_title: 'Do you really want to delete this view?',
    labels: {
      name: 'Name',
    },
    placeholders: {
      name: 'View name',
    },
    template: {
      name: 'View #1',
    },
  },
  // brick scene
  brick: {
    title: 'Brick',
    add: 'Add brick',
    edit: 'Edit',
    new: 'New brick type',
    customization: 'Customization',
    symbol: 'Symbol',
    text: 'Text',
    modal_title: 'Do you really want to delete this brick?',
    labels: {
      name: 'Name',
      attributes: 'Attributes',
      variants: 'Variants',
      nonable: 'Nonable',
      defaultable: 'Defautable'
    },
    placeholders: {
      name: 'Brick name',
      attributes: 'Attributes',
      variants: 'Variants',
      nonable: 'Nonable',
      defaultable: 'Defautable'
    },
    template: {
      name: 'Brick',
      attributes: 'Attributes',
      variants: 'Variants',
      nonable: 'Nonable',
      defaultable: 'Defautable'
    },
  },
  // Attributes scene
  attribute: {
    title: 'Attribute',
    add: 'Add Attribute',
    edit: 'Edit',
    new: 'New Attribute type',
    customization: 'Customization',
    symbol: 'Symbol',
    text: 'Text',
    modal_title: 'Do you really want to delete this Attribute?',
    attribute: 'Attribute',
    variation: 'Variation',
    isLinked: 'Is Linked?',
    view_attribute: 'View Attribute',
    attribute_saved: 'Attribute saved',
    labels: {
      name: 'Name',
      attributes: 'Attributes',
      upload: 'Upload',
      blending_mode: 'Blending mode',
      blending_value: 'Blending value',
      typeface_name: 'Typeface name',
      typeface_size: 'Typeface size',
      typeface_color: 'Typeface color',
      typeface_text_align: 'Typeface text align',
      typeface_text_transform: 'Typeface text transform',
      typeface_x: 'Typeface x position',
      typeface_y: 'Typeface y position',
      typeface_orientation: 'Typeface orientation',
      typeface_character_limit: 'Typeface characeter limit',
      typeface_rotate: 'Typeface rotation (°)',
      typeface_shadow: 'Typeface shadow (1px 1px #FFFFFF)',
      asset: 'Asset',
      swatch: 'Swatch',
      symbol_size: 'Symbol size',
      symbol_color: 'Symbol color',
      symbol_x: 'Symbol x position',
      symbol_y: 'Symbol y position',
    },
    placeholders: {
      name: 'Attribute name',
      attributes: 'Attributes',
      upload: 'Upload',
      blending_mode: 'Blending mode',
      blending_value: 'Blending value',
      typeface_name: 'Typeface name',
      typeface_size: 'Typeface size',
      typeface_color: 'Typeface color',
      typeface_text_align: 'Typeface text align',
      typeface_text_transform: 'Typeface text transform',
      typeface_x: 'Typeface x position',
      typeface_y: 'Typeface y position',
      typeface_orientation: 'Typeface orientation',
      typeface_character_limit: 'Typeface characeter limit',
      typeface_rotate: 'Typeface rotation (°)',
      typeface_shadow: 'Typeface shadow (1px 1px #FFFFFF)',
      asset: 'Asset',
      swatch: 'Swatch',
      symbol_size: 'Symbol size',
      symbol_color: 'Symbol color',
      symbol_x: 'Symbol x position',
      symbol_y: 'Symbol y position',
      linkKey: 'Link key',
    },
    template: {
      name: 'Attribute',
      attributes: 'Attributes',
      upload: 'Upload',
      blending_mode: 'Blending mode',
      blending_value: 'Blending value',
      typeface_name: 'Typeface name',
      typeface_size: 'Typeface size',
      typeface_color: 'Typeface color',
      typeface_text_align: 'Typeface text align',
      typeface_text_transform: 'Typeface text transform',
      typeface_x: 'Typeface x position',
      typeface_y: 'Typeface y position',
      typeface_orientation: 'Typeface orientation',
      typeface_character_limit: 'Typeface characeter limit',
      typeface_rotate: 'Typeface rotation (°)',
      typeface_shadow: 'Typeface shadow (1px 1px #FFFFFF)',
      asset: 'Asset',
      swatch: 'Swatch',
      symbol_size: 'Symbol size',
      symbol_color: 'Symbol color',
      symbol_x: 'Symbol x position',
      symbol_y: 'Symbol y position',
    },
  },
  // renderer
  renderer: {
    noViews: 'Please create a view',
  },
  // login, signup
  youremail: 'Your email',
  email_placeholder: 'you@company.com',
  yourpassword: 'Your password',
  password_placeholder: 'Your password',
  forgot: 'Forgot your password?',
  text_placeholder: 'Type your text',
  // login, forgot
  noaccount: 'Don\'t have an account yet?',
  // login
  login: 'Login',
  save: 'Save',
  publish: 'Publish',
  delete: 'delete',
  close: 'close',
  duplicate: 'duplicate',
  // forgot
  recover: 'Recover password',
  sendmail: 'Send email',
  tologin: 'Back to log in',
  // Recover
  reset: 'Reset',
  reset_password: 'Reset password',
  // signup
  signup: 'Sign up',
  confirm: 'Confirm password',
  confirm_placeholder: 'Confirm password',
  your_organization: 'Your organization',
  your_organization_placeholder: 'My site',
  getstarted: 'Get started',
  hasaccount: 'Already own an account?',
  terms: 'I agreee to the terms and conditions of Fine&Candy',
  emailEmpty: 'Email cannot be empty',
  passwordEmpty: 'Password cannot be empty',
  passwordsDontMatch: 'Passwords do not match',
  // settings
  logout: 'Log out',
  organization_provider: 'Organization provider',
  active_organization: 'Active organization',
  organization_secret_key: 'Organization Secret Key',
  // WoocommerceForm
  host: 'Host',
  host_placeholder: 'yoursite.wordpress.com',
  consumer_key: 'Consumer key',
  consumer_key_placeholder: 'ck_****************************************',
  secret_key: 'Secret key',
  secret_key_placeholder: 'cs_****************************************',
  wc_version: 'WooCommerce version',
  distincion_category_id: 'Distinction Category ID',
  distincion_category_id_placeholder: '1234',
  woocommerce_action: 'Woocommerce Action',
  woocommerce_action_placeholder: 'WOOCOMMERCE_AJAX_ADD_TO_CART',
  add_to_bag: "Add to bag",
  // app scene
  settings: 'Settings',
  // projects scene
  editproject: '● ● ●',
  // add project tile
  newproject: 'New project',
  // Console messages
  errSavingState: 'There was a problem saving application state to localStorage',
  errLoadingState: 'There was a problem with localStorage. Please check your browser\'s privacy settings.',
  errLoadingUrl: 'There was a problem with date from Url. Please check your browser\'s privacy settings.',
  // Footer
  footer_project: 'Distinction',
  footer_by: 'by',
  footer_gaspardbruno: 'Gaspard + Bruno',
  footer_copyright: 'Copyright by Gaspard+Bruno Ltd, 2020. All rights reserved. All materials on this website are intellectual property of Gaspard+Bruno Ltd.',
  footer_support: 'Support',
  footer_business: 'Business',
}
