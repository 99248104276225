import { produce } from 'immer'

const getIdField = (entity, item) => entity === 'user' ? item.attributes.slug : item.id

export default produce((
  state = {},
  { type, entity, id,  response: {data, included } = {} }
) => {
  if (type === 'deleteData') {
    state[entity][id]._deleted = true
    return state
  }
  if (type == 'setBackground') {
      state[entity] = data.backgroundImage
  }
  if (type == 'setViewAttributeBlendingValue') {
      state[entity][data.viewAttributeId].attributes.blendingValue = data.blendingValue
  }
  if (type == 'setState') {
      return JSON.parse(data.attributes.data).data
  }
  if (type !== 'setData') return state
  if (!state[entity]) state[entity] = {}

  const dataAsArray = Array.isArray(data) ? data : [data]
  dataAsArray.forEach(item => {
    state[entity][getIdField(entity, item)] = item
  })
  included && included.forEach(item => {
    const { type: includedEntity } = item
    if (!state[includedEntity])
      state[includedEntity] = {}
    state[includedEntity][getIdField(includedEntity, item)] = item
  })
  return state
})
