// mixed selectors

import { createSelector } from 'reselect'

import { _getToken, _getTokenData, _mkGetSelection, _getAttrSelection } from 'select/ui'
import { _getUserData, _getOrganizationData, _getWoocommerceOrganizationData, _getProjectData, _getAllProjects, _getViewData, _getAllViews, _getAllBricks, _getAllViewBricks, _getAllViewAttributes, _getCustomizationAttributes, _getSymbolAttributes, _getTextAttributes, _getViewBrickData, _getBrickData, _getCustomizationAttributeData, _getTextAttributeData, _getSymbolAttributeData, _getViewAttributeData, _getPreviewData, _getBacgroundImageData} from 'select/data'

// INTERNAL private selectors (use uderscore, do not export)

const _getUser = createSelector(
 _getTokenData,
 _getUserData,
 (tokenData, users) => tokenData && users && users[tokenData.slug]
)

const _getDefaultOrganizationId = createSelector(
  _getUser,
  ({ relationships: { organizations: { data: [{ id }] } } }) => id
)

const _getActiveOrganizationId = createSelector(
  _mkGetSelection('organization'),
  _getDefaultOrganizationId,
  (explicitId, defaultId) => explicitId || localStorage.getItem("curren_organization") || defaultId
)

const _getActiveOrganization = createSelector(
  _getActiveOrganizationId,
  _getOrganizationData,
  (id, data) => data[id]
)

const _getActiveProject = createSelector(
  _mkGetSelection('project'),
  _getProjectData,
  (id, data) => !id ? data && Object.values(data)[0] : data && data[id]
  // (id, data) => id && data ? data[id] : {}
)
const _getActiveProjectPreview = createSelector(
  _mkGetSelection('project'),
  _getProjectData,
  // (id, data) => !id ? data && Object.values(data)[0] : data && data[id]
  (id, data) => data ? Object.values(data)[0] : {}
  // (id, data) => id && data ? data[id] : {}
)

const _getActiveProjectConsumer = createSelector(
  _mkGetSelection('project'),
  _getProjectData,
  (id, data) => id && data ? data[id] : {}
)

const _getProjectViews = createSelector(
  _getAllViews,
  _getActiveProject, // FIX: duplicating selector
  (views, {id} ) => views && views.filter( ({relationships: {project: { data: {id: projectId }}}}) => projectId === id )
)

const _getProjectViewsPreview = createSelector(
  _getAllViews,
  _getActiveProjectPreview, // FIX: duplicating selector
  (views, {id} ) => views && views.filter( ({relationships: {project: { data: {id: projectId }}}}) => projectId === id )
)



const _getProjectViewsConsumer = createSelector(
  _getAllViews,
  _getActiveProjectConsumer, // FIX: duplicating selector
  (views, {id} ) => views && views.filter( ({relationships: {project: { data: {id: projectId }}}}) => projectId === id )
)

const _getDefaultView = createSelector(
  _getProjectViews,
  views => views && views.length > 0 ? views[0] : null
)

const _getActiveViewData = createSelector(
  _mkGetSelection('view'),
  _getActiveProject,
  _getViewData,
  (explicitId, {id} = {}, data) => {
    // if (explicitId === undefined) return defaultView
    if (explicitId === null) return
    return data && data[explicitId]

  }
)


const _getActiveView = createSelector(
  _mkGetSelection('view'),
  _getActiveProject,
  _getViewData,
  (explicitId, {id} = {}, data) =>
    !explicitId ? data && Object.values(data).filter( ({relationships: {project: { data: {id: projectId }}}}) => projectId === id )[0] : data && data[explicitId]
)

const _getViewBricks = createSelector(
  _getAllViewBricks,
  _getActiveView,
  (viewBricks, { id } ) => !id ? viewBricks && Object.values(viewBricks)[0] : viewBricks && viewBricks.filter( ({attributes: {viewId}}) => viewId.toString() === id )
)

const _getOrderedViewBricks = createSelector(
  _getViewBricks,
  viewBricks => viewBricks.sort((a, b) => {
    if(a.attributes.order < b.attributes.order) return -1
    if(a.attributes.order > b.attributes.order) return 1
    return 0
  })
)

const _getActiveBrick = createSelector(
  _mkGetSelection('brick'),
  _getAllBricks,
  ( activeId, bricks) => activeId ? bricks.filter( ({ id }) => activeId === id )[0] : null
)

const _getActiveAttributes = createSelector(
  _getActiveBrick,
  _getCustomizationAttributes,
  _getSymbolAttributes,
  _getTextAttributes,
  ( { id, attributes: { brickTypeId } }, customizationAttributes, symbolAttributes, textAttributes ) => {
    switch(brickTypeId) {
        case 1:
          return customizationAttributes ? customizationAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []
        case 2:
          return textAttributes ? textAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []
        case 3:
          return symbolAttributes ? symbolAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []
        default:
          return []
      }
    }
)

const _getActiveBrickConsumer = createSelector(
  _mkGetSelection('brick'),
  _getAllBricks,
  ( activeId, bricks) => activeId ? bricks.filter( ({ id }) => activeId === id )[0] : bricks && Object.values(bricks)[0]
)

const _getActiveAttributesConsumer = createSelector(
  _getActiveBrickConsumer,
  _getCustomizationAttributes,
  _getSymbolAttributes,
  _getTextAttributes,
  ( { id, attributes: { brickTypeId } }, customizationAttributes, symbolAttributes, textAttributes ) => {
    switch(brickTypeId) {
        case 1:
          return customizationAttributes ? customizationAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []
        case 2:
          return textAttributes ? textAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []
        case 3:
          return symbolAttributes ? symbolAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []
        default:
          return []
      }
    }
)

const _getActiveViewAttributeData = createSelector(
  _getActiveView,
  _mkGetSelection('attribute'),
  _getAllViewAttributes,
  ( view, id, viewAttributes ) => (view && viewAttributes) && viewAttributes.filter( ({ attributes: { attributableId, viewId } }) => (id && view.id && attributableId.toString() === id.toString() && viewId.toString() === view.id.toString()) )[0]
)

export const _getActiveViewAttribute = createSelector(
  _getActiveViewAttributeData,
  viewAttribute => {
    if (!viewAttribute) return
    const { id, attributes } = viewAttribute
    return { ...attributes, id }
  }
)

const _getActiveViewAttributes = createSelector(
  _getActiveView,
  _getAllViewAttributes,
  ( view, viewAttributes ) => (view && viewAttributes) ? viewAttributes.filter( ({ attributes: { attributableId, viewId } }) => (view.id && viewId.toString() === view.id.toString()) ) : []
)

const _getAttributes = createSelector(
  _getAllBricks,
  _getCustomizationAttributes,
  _getSymbolAttributes,
  _getTextAttributes,
  ( bricks, customizationAttributes, symbolAttributes, textAttributes  ) => bricks.map(({ id, attributes: { brickTypeId } }) => {
     switch(brickTypeId) {
       case 1:
         return {
           [id] : customizationAttributes ? customizationAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []
         }
       case 2:
         return {
           [id] : textAttributes ? textAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []
         }
      case 3:
         return {
           [id] : symbolAttributes ? symbolAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []
         }
      default:
         return {
           [id] :  []
         }
     }
   })
)


const _getDefaulAttrs = createSelector(
  _getActiveView,
  _getViewAttributeData,
  _getCustomizationAttributeData,
  _getTextAttributeData,
  _getSymbolAttributeData,
  _getBrickData,
  _getViewBrickData,
  (
    view,
    viewAttrs,
    customAttrs,
    textAttrs,
    symbolAttrs,
    bricks,
    viewBricks
  ) => {
    if (view) {
      let { id: viewId } = view
      let attrs = { ...customAttrs, ...textAttrs, ...symbolAttrs }

      let viewAttr = viewAttrs ? Object.values(viewAttrs).filter(
        ({
          attributes: {
            viewId: vaViewId,
          }
        }) => vaViewId == viewId
      ) : []

      let attrss = []
      let defaultAttrs = []

      viewAttr.forEach( // Each pick is an attribute of some type (custom, text, symbol)
        ({ id , attributes: { attributableId } }) => {
            let attr = Object.values(attrs).find(({
                id: attrId

              }) => attrId == attributableId.toString())
            if (attr && attr.attributes && attr.attributes.brickId) {

              if (!attrss.find(({
                  brickId: nid

                }) => nid == attr.attributes.brickId)) {
                  if (!defaultAttrs[attr.attributes.brickId]) {
                    defaultAttrs[attr.attributes.brickId] = []
                    defaultAttrs[attr.attributes.brickId]["defaultAttributes"] = []
                    defaultAttrs[attr.attributes.brickId]["otherAttributes"] = []
                  }
                  attrss.push({
                     'attrId': id,
                     'brickId': attr.attributes.brickId,
                     'attributes': attr.attributes
                   })
                  defaultAttrs[attr.attributes.brickId]["defaultAttributes"].push([attributableId.toString(), attr.type])

              } else {
                  defaultAttrs[attr.attributes.brickId]["otherAttributes"].push([attributableId.toString(), attr.type])
              }
            }
        })

      return defaultAttrs
    } else {
      return []
    }
  }
)


// MEMOIZED public selectors (export for use outside the selector library on react components/hooks and sagas)

export const getAllState = ({ ui, data }) => ({ ui: ui, data: data })

export const getPersistentState = createSelector(
  _getToken,
  token => ({ ui: { token }})
)

export const getUser = _getUser

export const getActiveOrganization = _getActiveOrganization

export const getActiveWoocoommerceOrganization = createSelector(
  _getActiveOrganization,
  _getWoocommerceOrganizationData,
  ({relationships: {woocommerceOrganization: {data: {id}}}}, wooOrgs) => wooOrgs && wooOrgs[id]
)

export const getProjects = createSelector(
  _getAllProjects,
  _getActiveOrganizationId,
  (projs, orgId) =>
    projs &&
    projs
      .filter(({_deleted}) => !_deleted )
      .filter(({relationships: {organization: { data: { id }}}}) => id === orgId )
)

export const getProjectViews = _getProjectViews

export const getActiveProject = createSelector(
  _mkGetSelection('project'),
  _getProjectData,
  (id, data) => id && data ? data[id] : null
)

export const getActiveProjectConsumer = createSelector(
  _mkGetSelection('project'),
  _getProjectData,
  (id, data) => !id ? data && Object.values(data)[0]  : data && data[id]
)

export const getActiveView = createSelector(
  _getActiveView,
  view => {
    if (!view) return
    const { id, attributes } = view
    return { ...attributes, id }
  }
)

export const getActiveViewData = createSelector(
  _getActiveViewData,
  view => {
    if (!view) return
    const { id, attributes } = view
    return { ...attributes, id }
  }
)

export const getViewBricks = _getViewBricks

export const getOrderedViewBricks = _getOrderedViewBricks

export const getOrderedBricks = createSelector(
  _getOrderedViewBricks,
  _getAllBricks,
  (viewBricks, bricks) =>
    viewBricks.map(
      ({attributes: { brickId }}) =>
        bricks.filter(
          brick => brick.id === brickId.toString() && brick
        )[0]
    )
)

export const getActiveBrick = createSelector(
  _getActiveBrick,
  brick => {
    if (!brick) return
    const { id, attributes } = brick
    return { ...attributes, id }
  }
)

export const getActiveAttributes = _getActiveAttributes

export const getActiveAttributeData = createSelector(
  _mkGetSelection('attribute'),
  _getActiveAttributes,
  // (id, data) =>  !id ? data && Object.values(data)[0] : data.filter(brick => id && brick.id === id.toString() && brick)[0]
  (id, data) => id && data ? data.filter(brick => id && brick.id === id.toString() && brick)[0] : null
)
export const getActiveAttributeConsumerData = createSelector(
  _mkGetSelection('attribute'),
  _getActiveAttributesConsumer,
  (id, data) =>  !id ? data && Object.values(data)[0] : data.filter(brick => id && brick.id === id.toString() && brick)[0]
  // (id, data) => id && data ? data.filter(brick => id && brick.id === id.toString() && brick)[0] : null
)

export const getActiveAttribute = createSelector(
  getActiveAttributeData,
  attribute => {
    if (!attribute) return
    const { id, attributes } = attribute
    return { ...attributes, id }
  }
)
export const getAttributes = _getAttributes

export const getViewAttributes = _getAllViewAttributes
export const getActiveViewAttribute = _getActiveViewAttribute
export const getActiveViewAttributes = _getActiveViewAttributes

export const getAttrSelection = _getAttrSelection

export const getLayers = createSelector(
  _getActiveView,
  // _getProjectViews,
  _getDefaulAttrs,
  _getAttrSelection,
  _getViewAttributeData,
  _getCustomizationAttributeData,
  _getTextAttributeData,
  _getSymbolAttributeData,
  _getBrickData,
  _getViewBrickData,
  (
    view,
    // views,
    defaultAttrs,
    picks,
    viewAttrs,
    customAttrs,
    textAttrs,
    symbolAttrs,
    bricks,
    viewBricks
  ) => {

  let layers = [] // The layer to be ordered, then returned

  // if (!views) return layers
  if (!view) return layers // While a new view is being added, we lose the current view selection

  // console.log("views", views)
  // views.forEach((view) => {



    let { id: viewId } = view
    let bricksHit = {} // Index of bricks hit so far (for single attributes selection)

    let picksToDisplay = []

    // if (!picks || picks.length === 0) {
    //   // apply defaults
    //   Object.values(defaultAttrs).forEach(
    //     (attrs) => {
    //       picksToDisplay.push(attrs["defaultAttributes"][0])
    //     }
    //   )
    // } else {
      // Object.values(defaultAttrs).forEach(
      //   (attrs) => {
      //     if (!picks.find(([attrId, attrType]) => attrId == attrs["defaultAttributes"][0][0] && attrType == attrs["defaultAttributes"][0][1])) {
      //       let otherAttributeAddedAlready = false
      //       attrs["otherAttributes"].forEach(([otherAttrId, otherAttrType]) => {
      //         if (picks.find(([nAattrId, nAttrType]) => nAattrId == otherAttrId && nAttrType == otherAttrType)) {
      //           otherAttributeAddedAlready =  true
      //         }
      //       })
      //       if (!otherAttributeAddedAlready) {
      //         picksToDisplay.push([attrs["defaultAttributes"][0][0], attrs["defaultAttributes"][0][1]])
      //       }
      //     }
      // })
      picks && picks.forEach(([attrId, attrType, textValue, variantName]) => {
        if (!picksToDisplay.find(([attrToDisplayId, attrToDisplayType]) => attrToDisplayId == attrId && attrToDisplayType == attrType)) {
          picksToDisplay.push([attrId, attrType, textValue, variantName])
        }
      })
    // }

    picksToDisplay && picksToDisplay.forEach( // Each pick is an attribute of some type (custom, text, symbol)
      ([attrId, attrType, textValue, variantName]) => {
        let attr, attrTable
        if (attrType === 'customizationAttribute') {
          attr = customAttrs[attrId]
          // The db table where this attribute is stored based off its type
          // Will be used to find the attr in the viewAttribute relation table
          attrTable = 'CustomizationAttribute'
        } else if(attrType === 'textAttribute') {
          attr = textAttrs[attrId]
          attrTable = 'TextAttribute'
        } else if(attrType === 'symbolAttribute') {
          attr = symbolAttrs[attrId]
          attrTable = 'SymbolAttribute'
        }
        let { attributes: { name /* The layer's name */, brickId, swatch }} = attr
        // Name found
        let { attributes: { name: brickName, brickTypeId: brickType, multipleSelection } } = bricks[brickId]
        // Brick name found
        if (!multipleSelection && bricksHit[brickId]) return
        bricksHit[brickId] = true
        // Let's go find the image
        let viewAttr = viewAttrs && Object.values(viewAttrs).find(
          ({
            attributes: {
              viewId: vaViewId,
              attributableId: vaAttrId,
              attributableType: vaAttrTable
            }
          }) => vaViewId == viewId && vaAttrId == attrId &&
            vaAttrTable === attrTable
        )
        // When switching views, we could have not found a match
        if (viewAttr === undefined) return
        let {
          attributes: {
            id,
            image, // The layer's image
            blendingMode, blendingValue, typefaceName, typefaceSize, typefaceColor, typefaceTextAlign, typefaceTextTransform, typefaceX, typefaceY, typefaceOrientation, typefaceCharacterLimit, typefaceRotate, typefaceShadow, symbolSize, symbolColor, symbolX, symbolY,
            visible: attrVisible,
          }
        } = viewAttr
        // Check if attribute was hidden for this view
        if (!attrVisible) return
        // Image found
        // Let's go find the order
        let viewBrick = Object.values(viewBricks).find(
          ({
            attributes: {
              viewId: vbViewId,
              brickId: vbBrickId,
            }
          }) => vbViewId == viewId && vbBrickId == brickId
        )
        // found viewBrick
        let { attributes: { visible: brickVisible, order } } = viewBrick
        let visible = attrVisible && brickVisible
        // Check now if brick was hidden for this view
        let layerId = id
        let layer = { layerId, attrTable, name, brickName, brickType, swatch, visible, order, image, blendingMode, blendingValue, textValue, typefaceName, typefaceSize, typefaceColor, typefaceTextAlign, typefaceTextTransform, typefaceX, typefaceY, typefaceOrientation, typefaceCharacterLimit, typefaceRotate, typefaceShadow, variantName, symbolSize, symbolColor, symbolX, symbolY, }
        layers.push(layer)
      }
    )
  // })
  // Sort layers by order field
  return layers.sort(({ order: a }, { order: b }) => a > b ? 1 : -1)
})

export const getLayeredViewAttributes = createSelector(
  // _getActiveView,
  // _getActiveViewAttributes,
  _getProjectViews,
  _getViewAttributeData,
  _getCustomizationAttributeData,
  _getTextAttributeData,
  _getSymbolAttributeData,
  _getBrickData,
  _getViewBrickData,
  (
    views,
    viewAttrs,
    customAttrs,
    textAttrs,
    symbolAttrs,
    bricks,
    viewBricks
  ) => {

  let layers = [] // The layer to be ordered, then returned

  if (!views || !viewAttrs) return layers
  // if (!view) return layers // While a new view is being added, we lose the current view selection

  views.forEach((view) => {

    if (!viewAttrs) return layers
    let { id: viewId } = view

    // Each pick is an attribute of some type (custom, text, symbol)
    viewAttrs && Object.values(viewAttrs) && Object.values(viewAttrs).forEach(
      ({type, attributes: {
        id,
        attributableType,
        attributableId,
        textValue,
        variantName,
        image,
        blendingMode, blendingValue, typefaceName, typefaceSize, typefaceColor, typefaceTextAlign, typefaceTextTransform, typefaceX, typefaceY, typefaceOrientation, typefaceCharacterLimit, typefaceRotate, typefaceShadow, symbolSize, symbolColor, symbolX, symbolY, visible }}) => {

        if (!visible) return

        let attr
        let attributeType = 'customizationAttribute'
        if (attributableType === 'CustomizationAttribute') {
          attr = customAttrs[attributableId]
          attributeType = 'customizationAttribute'
        } else if(attributableType === 'TextAttribute') {
          attr = textAttrs[attributableId]
          attributeType = 'textAttribute'
        } else if(attributableType === 'SymbolAttribute') {
          attr = symbolAttrs[attributableId]
          attributeType = 'symbolAttribute'
        }
        let { attributes: { name /* The layer's name */, brickId, swatch }} = attr
        // Name found
        let { attributes: { name: brickName, brickTypeId: brickType, multipleSelection} } = bricks[brickId]
        // Image found

        // Let's go find the order
        let viewBrick = Object.values(viewBricks).find(
          ({
            attributes: {
              viewId: vbViewId,
              brickId: vbBrickId,
            }
          }) => vbViewId == viewId && vbBrickId == brickId
        )
        if (!viewBrick) return

        // // found viewBrick
        let { attributes: { visible: brickVisible, order } } = viewBrick
        if (!brickVisible) return
        // // Check now if brick was hidden   for this view
        let layer = { id, viewId, brickId, attributableId, attributeType, name, brickName, brickType, swatch, visible, order, image, blendingMode, blendingValue, textValue, typefaceName, typefaceSize, typefaceColor, typefaceTextAlign, typefaceTextTransform, typefaceX, typefaceY, typefaceOrientation, typefaceCharacterLimit, typefaceRotate, typefaceShadow, variantName, symbolSize, symbolColor, symbolX, symbolY }
        layers.push(layer)
      }
    )
  })
  // Sort layers by order field
  return layers.sort(({ order: a }, { order: b }) => a > b ? 1 : -1)
})

export const getLayeredViewAttributesPreview = createSelector(
  // _getActiveView,
  // _getActiveViewAttributes,
  _getProjectViewsPreview,
  _getViewAttributeData,
  _getCustomizationAttributeData,
  _getTextAttributeData,
  _getSymbolAttributeData,
  _getBrickData,
  _getViewBrickData,
  (
    views,
    viewAttrs,
    customAttrs,
    textAttrs,
    symbolAttrs,
    bricks,
    viewBricks
  ) => {

  let layers = [] // The layer to be ordered, then returned

  if (!views || !viewAttrs) return layers
  // if (!view) return layers // While a new view is being added, we lose the current view selection

  views.forEach((view) => {

    if (!viewAttrs) return layers
    let { id: viewId } = view

    // Each pick is an attribute of some type (custom, text, symbol)
    viewAttrs && Object.values(viewAttrs) && Object.values(viewAttrs).forEach(
      ({type, attributes: {
        id,
        attributableType,
        attributableId,
        textValue,
        variantName,
        image,
        blendingMode, blendingValue, typefaceName, typefaceSize, typefaceColor, typefaceTextAlign, typefaceTextTransform, typefaceX, typefaceY, typefaceOrientation, typefaceCharacterLimit, typefaceRotate, typefaceShadow, symbolSize, symbolColor, symbolX, symbolY, visible }}) => {

        if (!visible) return

        let attr
        let attributeType = 'customizationAttribute'
        if (attributableType === 'CustomizationAttribute') {
          attr = customAttrs[attributableId]
          attributeType = 'customizationAttribute'
        } else if(attributableType === 'TextAttribute') {
          attr = textAttrs[attributableId]
          attributeType = 'textAttribute'
        } else if(attributableType === 'SymbolAttribute') {
          attr = symbolAttrs[attributableId]
          attributeType = 'symbolAttribute'
        }
        let { attributes: { name /* The layer's name */, brickId, swatch }} = attr
        // Name found
        let { attributes: { name: brickName, brickTypeId: brickType, multipleSelection} } = bricks[brickId]
        // Image found

        // Let's go find the order
        let viewBrick = Object.values(viewBricks).find(
          ({
            attributes: {
              viewId: vbViewId,
              brickId: vbBrickId,
            }
          }) => vbViewId == viewId && vbBrickId == brickId
        )
        if (!viewBrick) return

        // // found viewBrick
        let { attributes: { visible: brickVisible, order } } = viewBrick
        if (!brickVisible) return
        // // Check now if brick was hidden   for this view
        let layer = { id, viewId, brickId, attributableId, attributeType, name, brickName, brickType, swatch, visible, order, image, blendingMode, blendingValue, textValue, typefaceName, typefaceSize, typefaceColor, typefaceTextAlign, typefaceTextTransform, typefaceX, typefaceY, typefaceOrientation, typefaceCharacterLimit, typefaceRotate, typefaceShadow, variantName, symbolSize, symbolColor, symbolX, symbolY }
        layers.push(layer)
      }
    )
  })
  // Sort layers by order field
  return layers.sort(({ order: a }, { order: b }) => a > b ? 1 : -1)
})

export const getLayeredViewAttributesConsumer = createSelector(
  // _getActiveView,
  // _getActiveViewAttributes,
  _getProjectViewsConsumer,
  _getViewAttributeData,
  _getCustomizationAttributeData,
  _getTextAttributeData,
  _getSymbolAttributeData,
  _getBrickData,
  _getViewBrickData,
  (
    views,
    viewAttrs,
    customAttrs,
    textAttrs,
    symbolAttrs,
    bricks,
    viewBricks
  ) => {

  let layers = [] // The layer to be ordered, then returned

  if (!views || !viewAttrs) return layers
  // if (!view) return layers // While a new view is being added, we lose the current view selection

  views.forEach((view) => {

    if (!viewAttrs) return layers
    let { id: viewId } = view

    // Each pick is an attribute of some type (custom, text, symbol)
    viewAttrs && Object.values(viewAttrs) && Object.values(viewAttrs).forEach(
      ({type, attributes: {
        id,
        attributableType,
        attributableId,
        textValue,
        variantName,
        image,
        blendingMode, blendingValue, typefaceName, typefaceSize, typefaceColor, typefaceTextAlign, typefaceTextTransform, typefaceX, typefaceY, typefaceOrientation, typefaceCharacterLimit, typefaceRotate, typefaceShadow, symbolSize, symbolColor, symbolX, symbolY, visible }}) => {

        if (!visible) return

        let attr
        let attributeType = 'customizationAttribute'
        if (attributableType === 'CustomizationAttribute') {
          attr = customAttrs[attributableId]
          attributeType = 'customizationAttribute'
        } else if(attributableType === 'TextAttribute') {
          attr = textAttrs[attributableId]
          attributeType = 'textAttribute'
        } else if(attributableType === 'SymbolAttribute') {
          attr = symbolAttrs[attributableId]
          attributeType = 'symbolAttribute'
        }
        let { attributes: { name /* The layer's name */, brickId, swatch }} = attr
        // Name found
        let { attributes: { name: brickName, brickTypeId: brickType, multipleSelection} } = bricks[brickId]
        // Image found

        // Let's go find the order
        let viewBrick = Object.values(viewBricks).find(
          ({
            attributes: {
              viewId: vbViewId,
              brickId: vbBrickId,
            }
          }) => vbViewId == viewId && vbBrickId == brickId
        )
        if (!viewBrick) return

        // // found viewBrick
        let { attributes: { visible: brickVisible, order } } = viewBrick
        if (!brickVisible) return
        // // Check now if brick was hidden   for this view
        let layer = { id, viewId, brickId, attributableId, attributeType, name, brickName, brickType, swatch, visible, order, image, blendingMode, blendingValue, textValue, typefaceName, typefaceSize, typefaceColor, typefaceTextAlign, typefaceTextTransform, typefaceX, typefaceY, typefaceOrientation, typefaceCharacterLimit, typefaceRotate, typefaceShadow, variantName, symbolSize, symbolColor, symbolX, symbolY }
        layers.push(layer)
      }
    )
  })
  // Sort layers by order field
  return layers.sort(({ order: a }, { order: b }) => a > b ? 1 : -1)
})

export const getTextSelections = createSelector(
  _getAttrSelection,
  (
    selection
  ) => {
    let textSelection = []

    selection.forEach((select) => {
      if (select[1] == "textAttribute" ) {
        let found = false;
        for(let i = 0; i < textSelection.length; i++) {
            if (textSelection[i].id == select[4]) {
                found = true;
                break;
            }
        }
        if (!found) {
          textSelection.push({
            'id': select[4],
            'value': select[2]
          })
        }
      }
    })


    return textSelection
  }
)
export const getFCCost = createSelector(
  _getActiveView,
  _getActiveViewAttributes,
  _getCustomizationAttributeData,
  _getTextAttributeData,
  _getSymbolAttributeData,
  _getBrickData,
  _getViewBrickData,
  _getAttrSelection,
  (
    view,
    viewAttrs,
    customAttrs,
    textAttrs,
    symbolAttrs,
    bricks,
    viewBricks,
    selection
  ) => {

      let maxLetter = 17
      let pricePerLetter = 1
      let costOfText = 4
      let priceIfSymbole = 8

      let cost = 0

      let hasSymbol = false
      let symbol = ""
      let hasText = false
      let text = ""
      let textType = ""

      let currency = "€"

      // console.log("textAttrs", textAttrs)

      selection && selection.forEach(([attrId, attrType, textValue, variantName, brickId]) => {

        if (hasSymbol == false && attrType === "symbolAttribute") {
          hasSymbol = true
          symbol = symbolAttrs[attrId].attributes.name
        }
        if (hasText === false && attrType === "textAttribute") {
          hasText = true
          text = textValue
          textType = textAttrs[attrId].attributes.name
        }
      })



      if (text) {
        cost += costOfText
        cost += pricePerLetter * (text.substring(0, maxLetter).replace(" ", "").length - 1)

        // eslint-disable-next-line
        if (typeof distinctionProductCategory != "undefined") {

          var box12 = [300, 301, 302, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318]
          var box8 = [319]
          var box4 = [303]

          // eslint-disable-next-line
          if (cost > 0 && box12.includes(distinctionProductCategory)) {
            cost = 12
          }

          // eslint-disable-next-line
          if (cost > 0 && box8.includes(distinctionProductCategory)) {
            cost = 10
          }

          // eslint-disable-next-line
          if (cost > 0 && box4.includes(distinctionProductCategory)) {
            cost = 5
          }

          //Free campaigns
          let now = new Date().getTime()
          // console.log("now", now)

          // eslint-disable-next-line
          if (typeof free_campaign_start_date != "undefined" && typeof free_campaign_end_date != "undefined") {

            // eslint-disable-next-line
            if (now >= free_campaign_start_date && now <= free_campaign_end_date) {
              if (cost > 0 && text.substring(0, maxLetter).replace(" ", "").length <= 3) {
                // console.log("free campaing")
                cost = 0
              }
            }
          }
        }
      }

      if (hasSymbol) {
        cost += priceIfSymbole
      }
      
      // Connect with plugin
      if (typeof distinctionPersonalizationCost != "undefined") {
        // eslint-disable-next-line
        distinctionPersonalizationCost = cost
      }
      if (typeof distinctionPersonalizationCurrency != "undefined") {
        // eslint-disable-next-line
        currency = distinctionPersonalizationCurrency
      }

      let costObject = {}
      costObject.display = "+ " + cost + " " + currency
      costObject.cost = cost
      costObject.currency = currency
      costObject.hasText = hasText
      costObject.text = text
      costObject.textType = textType
      costObject.hasSymbol = hasSymbol
      costObject.symbol = symbol

      return costObject
    }
)

export const getPreview = createSelector(
  _getPreviewData,
  ( preview ) => preview && Object.values(preview)[Object.values(preview).length-1]
)

export const getBackgroundImage = createSelector(
  _getBacgroundImageData,
  ( backgroundImage ) => backgroundImage && backgroundImage
)

const _getAlAttributes = createSelector(
  _getAllBricks,
  _getCustomizationAttributes,
  _getSymbolAttributes,
  _getTextAttributes,
  ( bricks, customizationAttributes, symbolAttributes, textAttributes  ) => bricks.map(({ id, attributes: { brickTypeId } }) => {
     switch(brickTypeId) {
       case 1:
         return customizationAttributes ? customizationAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []

       case 2:
         return textAttributes ? textAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []

        case 3:
         return symbolAttributes ? symbolAttributes.sort((a, b) => a.attributes.order - b.attributes.order).filter( ({ attributes: { brickId }  }) => brickId.toString() === id ) : []
     }
   })
)

export const getLinkedAttributes = createSelector(
  _getAlAttributes,
  // What a mess
  (data) => [].concat.apply([], data.filter(k => k.length > 0)).filter(e => e.attributes.isLinked == true)
)
