import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import copy from 'copy'
// import { useFetchData } from 'hooks'
import { getActiveView, getActiveProjectConsumer } from 'select'

import { _getViewData } from 'select/data'

import { setActiveProjectId, setActiveViewId } from 'actions'

import BrickList from './BrickList'

import './CustomizationPanel.css'

const CustomizationPanel = ({ views, view, project, dispatch, setActiveViewId, setActiveProjectId, brickIndex }) => (!views || !project)
  ? null // Loading
  : <div className="CustomizationPanel">

      <BrickList brickIndex={brickIndex}/>

    </div>

export default connect(
  state => ({
    project: getActiveProjectConsumer(state),
    views: _getViewData(state),
    view: getActiveView(state),
  }),
  dispatch => bindActionCreators({
    setActiveProjectId,
    setActiveViewId,
  }, dispatch)
)(CustomizationPanel)
