import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import * as qs from 'query-string';

import PreviewWindow from 'scenes/consumerapp/PreviewWindow'
import Renderer from 'scenes/consumerapp/Renderer'
import CustomizationPanel from 'scenes/consumerapp/CustomizationPanel'
import AddToBag from 'scenes/consumerapp/AddToBag'
import Cost from 'scenes/consumerapp/Cost'

// import * as service  Worker from 'serviceWorker'
import rootReducer from 'reducers/root'
import rootSaga from 'saga/consumerRoot'

import copy from 'copy'

import 'sass/boot.scss'

const loadStateFromStorage = () => {
  let state, item
  try {
    item = localStorage.getItem('state')
  } catch(e) {
    console.log(copy['errLoadingState'])
  }
  if (item !== null) state = JSON.parse(item)
  return state
}

const loadStateFromUrl = (store) => {
  let url = ""
  if (typeof globalDistinctionUrl != "undefined") {
    // eslint-disable-next-line
    url = globalDistinctionUrl
  } else {
    url = (qs.parse(window.location.search) && qs.parse(window.location.search).url) ? qs.parse(window.location.search).url : false
  }

  if (url) {
    store.dispatch({type: 'setUrl', url})
  }
}

const loadStateFromPreview = (store) => {
  const preview = (qs.parse(window.location.search) && qs.parse(window.location.search).preview) ? qs.parse(window.location.search).preview : false
  if (preview) {
    store.dispatch({type: 'setPreview', preview})
  }
}


const sagaMiddleware = createSagaMiddleware({ context: { history: null }})
const middleware = [ sagaMiddleware ]

const enhancers = []
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension())
}

const store = createStore(
  rootReducer,
  loadStateFromStorage(),
  compose(applyMiddleware(...middleware), ...enhancers)
)

sagaMiddleware.run(rootSaga)

loadStateFromUrl(store)
loadStateFromPreview(store)

document.getElementById('customization-panel') && ReactDOM.render(
  <Provider store={store}>
    <CustomizationPanel />
  </Provider>,
  document.getElementById('customization-panel')
)

document.getElementById('customization-panel-0') && ReactDOM.render(
  <Provider store={store}>
    <CustomizationPanel brickIndex={0} />
  </Provider>,
  document.getElementById('customization-panel-0')
)

document.getElementById('customization-panel-1') && ReactDOM.render(
  <Provider store={store}>
    <CustomizationPanel brickIndex={1} />
  </Provider>,
  document.getElementById('customization-panel-1')
)

document.getElementById('customization-panel-2') && ReactDOM.render(
  <Provider store={store}>
    <CustomizationPanel brickIndex={2} />
  </Provider>,
  document.getElementById('customization-panel-2')
)

document.getElementById('customization-panel-3') && ReactDOM.render(
  <Provider store={store}>
    <CustomizationPanel brickIndex={3} />
  </Provider>,
  document.getElementById('customization-panel-3')
)

document.getElementById('customization-panel-4') && ReactDOM.render(
  <Provider store={store}>
    <CustomizationPanel brickIndex={4} />
  </Provider>,
  document.getElementById('customization-panel-4')
)

document.getElementById('customization-panel-5') && ReactDOM.render(
  <Provider store={store}>
    <CustomizationPanel brickIndex={5} />
  </Provider>,
  document.getElementById('customization-panel-5')
)

document.getElementById('preview-window') && ReactDOM.render(
  <Provider store={store}>
    <PreviewWindow />
  </Provider>,
  document.getElementById('preview-window')
)

document.getElementById('add-to-bag') && ReactDOM.render(
  <Provider store={store}>
    <AddToBag />
  </Provider>,
  document.getElementById('add-to-bag')
)

document.getElementById('cost') && ReactDOM.render(
  <Provider store={store}>
    <Cost />
  </Provider>,
  document.getElementById('cost')
)
document.getElementById('renderer-preview') && ReactDOM.render(
  <Provider store={store}>
    <Renderer />
  </Provider>,
  document.getElementById('renderer-preview')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register()
