import { createSelector } from 'reselect'
import jwt_decode from 'jwt-decode'

// INTERNAL private selectors (use uderscore, do not export)
// external PRIVATE selectors (use underscore, export for use by other selectors ONLY)

// INTERNAL
const __getUi = ({ ui }) => ui
const __getMessages = s => __getUi(s).messages
const __getDisplayFlags = s => __getUi(s).displayFlags
const __getRequests = s => __getUi(s).requests
const __getSelection = s => __getUi(s).selection
// const __getPathname = s => __getUi(s).pathname

const __getErrorMessages = createSelector(
  __getMessages,
  messages => messages.filter(m => m.messageType === 'error')
)

// EXTERNAL
export const _getToken = s => __getUi(s).token
export const _getAttrSelection = s => __getUi(s).attrSelection

export const _getTokenData = createSelector(
  _getToken,
  token => token && jwt_decode(token)
)

export const _mkGetSelection = entity => createSelector(
  __getSelection,
  selection => selection[entity]
)

export const _getUrl = s => __getUi(s).url
export const _getSelectors = s => __getUi(s).selectors

//
// PUBLIC selectors (export for use outside the selector library on react components/hooks and sagas)
//

export const getToken = _getToken
export const getTokenData = _getTokenData

export const getError = errorType => createSelector(
  __getErrorMessages,
  messages => messages.find(({ key }) => key === errorType)
)

export const getTopMessage = createSelector(
  __getMessages,
  messages => messages[messages.length - 1]
)

export const isRequesting = requestType => createSelector(
  __getRequests,
  requests => requests[requestType] === true
)

export const shouldDisplay = flagName => createSelector(
  __getDisplayFlags,
  ({[flagName]: val}) => val
)

export const getUrl = _getUrl
