import React, { useState, useEffect } from 'react'
import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import copy from 'copy'
// import { useFetchData } from 'hooks'
import { setActiveBrickId, toggleAttr, savePreview } from 'actions'
import { getProducts, _getWoocommerceOrganizationData } from 'select/data'
import { getPreview, getLayers, getFCCost, getActiveWoocoommerceOrganization, getActiveProjectConsumer, getAllState } from 'select'
// import { shouldDisplay } from 'select/ui'

import InnerLoader from 'scenes/app/InnerLoader'

import './AddToBag.css'

export const AddToBag = connect(
  state => ({
    // Selectors
    project: getActiveProjectConsumer(state),
    products: getProducts(state),
    layers: getLayers(state),
    cost: getFCCost(state),
    woocoommerceOrganization: _getWoocommerceOrganizationData(state),
    preview: getPreview(state),
    activeProject: getActiveProjectConsumer(state),
    allState: getAllState(state),
  }),
  dispatch => bindActionCreators({
    // Actions
    savePreview,
  }, dispatch)
)(({
  // Props
  project,
  products,
  layers,
  cost,
  woocoommerceOrganization,
  preview,
  savePreview,
  activeProject,
  allState,
}) => {

  const [bricksList, setBricksList] = useState()
  const [viewBricksList, setViewBricksList] = useState()
  const [text, setText] = useState()

  const [previewWidth, setPreviewWidth] = useState()
  const [previewHeight, setPreviewHeight] = useState()

  useEffect(() => {
    // setBricksList(bricks)
    // setViewBricksList(viewBricks)
    setText('')
    setPreviewWidth(586)
    setPreviewHeight(698)
  })


  const getProductId = () => {

    // For test purpose
    const distinctionProductId = 5345

    if (typeof distinctionProductId != "undefined") {
      // eslint-disable-next-line
      return distinctionProductId
    } else {
      return null
    }
  }

  const getVariationId = (layers, products) => {

    //Get variantNames of each layer
    let variantNames = []
    layers.map((layer) => {
      if (layer.variantName) {
        variantNames.push(layer.variantName)
      }
    })

    //Get product variation
    let product_variations = []
    products[0].attributes.attributes.map((attribute) => {
      product_variations.push("attribute_" + attribute.name.toLowerCase())
    })

    //Get default variations
    let default_product_variations = []
    products[0].attributes.defaultAttributes.map((attribute) => {
      default_product_variations.push({
        key: "attribute_" + attribute.name.toLowerCase(),
        value: attribute.option
      })
    })

    const test = [{
        key: "attribute_distinction_symbol",
        value: "symbol_no"
      },{
        key: "attribute_distinction_engraving",
        value: ""
      },{
        key: "attribute_size",
        value: "A5"
      }
    ]

    //Find Variation ID
    let variation_id = 0
    products[0].attributes.productVariations.map((variation) => {
      let found = true


      test.map((test_variation) => {
        if (variation.variation_attributes[test_variation.key] != test_variation.value) {
          found = false
        }
      })

      if (found) {
        variation_id = variation.variation_id
      }
    })

    return variation_id != 0 ? variation_id : false
  }

  const appendVariantValues = (data, layers) => {
    layers.map((layer) => {

      if (layer.variantName && layer.variantName.key) {
        data[layer.variantName.key.replace("attribute_", "") + "_value"] = layer.textValue
      }
    })

    return data
  }

  const appendValues = (data, layers) => {
    layers.map((layer) => {

        if (layer.brickType === 2) {
          data[layer.brickName] = layer.textValue
        } else {
          data[layer.brickName] = layer.name
        }
    })
    return data
  }

  return <div className="AddToBag">
        <button className={project && project.attributes.addToBagClass + " text-button"}
          onClick={ () => {
            if (activeProject && activeProject.relationships) {

              // 'width': document.getElementById('renderer').offsetWidth,
              // 'height': document.getElementById('renderer').offsetHeight,
              if (typeof distinctionPreviewHeight != "undefined") {
                // eslint-disable-next-line
                setPreviewHeight(distinctionPreviewHeight)
              }
              if (typeof distinctionPreviewWidth != "undefined") {
                // eslint-disable-next-line
                setPreviewHeight(distinctionPreviewWidth)
              }

              savePreview({
                  'project_id': activeProject.id,
                  'organization_id': activeProject.relationships.organization.data.id,
                  'visible': true,
                  'data': JSON.stringify(allState),
                  'width': previewWidth,
                  'height': previewHeight,
                })
            }

            // if (getProductId()) {
            //   console.log(appendValues({
            //     product_id:getProductId(),
            //     product_sku:"",
            //     quantity: 1,
            //     host: woocoommerceOrganization["1"]["attributes"]["host"],
            //     action: woocoommerceOrganization["1"]["attributes"]["action"],
            //   }, layers))
            // } else {
            //   console.log(appendVariantValues({
            //     product_id: products[0].id,
            //     product_sku:"",
            //     quantity: 1,
            //     variation_id: getProductId() ? getProductId() : getVariationId(layers, products),
            //     host: woocoommerceOrganization["1"]["attributes"]["host"],
            //     action: woocoommerceOrganization["1"]["attributes"]["action"],
            //   }, layers))
            // }
          }
        }

        >{ copy.add_to_bag }</button>
     </div>
})

export default AddToBag
