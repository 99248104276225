import { createSelector } from 'reselect'

// INTERNAL private selectors (use uderscore, do not export)

const __getData = ({ data }) => data

const _getProductData = createSelector(
  __getData,
  ({ woocommerceProduct }) => woocommerceProduct
)

// external PRIVATE selectors (use underscore, export for use by other selectors ONLY)

export const _getUserData = createSelector(
  __getData,
  ({ user }) => user
)

export const _getOrganizationData = createSelector(
  __getData,
  ({ organization }) => organization
)

export const _getWoocommerceOrganizationData = createSelector(
  __getData,
  ({ woocommerceOrganization }) => woocommerceOrganization
)

export const _getProjectData = createSelector(
  __getData,
  ({ project }) => project
)

export const _getViewData = createSelector(
  __getData,
  ({ view }) => view
)

export const _getSymbolAttributeData = createSelector(
  __getData,
  ({ symbolAttribute }) => symbolAttribute
)

export const _getTextAttributeData = createSelector(
  __getData,
  ({ textAttribute }) => textAttribute
)

export const _getBrickData = createSelector(
  __getData,
  ({ brick }) => brick
)

export const _getCustomizationAttributeData = createSelector(
  __getData,
  ({ customizationAttribute }) => customizationAttribute
)

export const _getViewBrickData = createSelector(
  __getData,
  ({ viewBrick }) => viewBrick
)

export  const _getViewAttributeData = createSelector(
  __getData,
  ({ viewAttribute }) => viewAttribute
)

export const _getAllBricks = createSelector(
  _getBrickData,
  data => data ? Object.values(data) : []
)

export const _getCustomizationAttributes = createSelector(
  _getCustomizationAttributeData,
  data => data ? Object.values(data) : []
)

export const _getSymbolAttributes = createSelector(
  _getSymbolAttributeData,
  data => data ? Object.values(data) : []
)

export const _getTextAttributes = createSelector(
  _getTextAttributeData,
  data => data ? Object.values(data) : []
)

export const _getAllViewBricks = createSelector(
  _getViewBrickData,
  data => data ? Object.values(data) : []
)

export const  _getAllViewAttributes = createSelector(
  _getViewAttributeData,
  data => data ? Object.values(data) : []
)

export const _getAllProjects = createSelector(
  _getProjectData,
  data => data ? Object.values(data) : []
)

export const _getAllViews = createSelector(
  _getViewData,
  data => data ? Object.values(data) : []
)

export const _getPreviewData = createSelector(
  __getData,
  ({ preview }) => preview
)

export const _getBacgroundImageData = createSelector(
  __getData,
  ({ backgroundImage }) => backgroundImage
)

// MEMOIZED public selectors (export for use outside the selector library on react components/hooks and sagas)

export const getOrganizations = createSelector(
  _getOrganizationData,
  data => data ? Object.values(data) : []
)

export const getProducts = createSelector(
  _getProductData,
  data => data ? Object.values(data) : []
)
