export const save = async (preview, project_id) => {
  const url = `${process.env.REACT_APP_BASE_URL}/v1/previews/?project_id=${project_id}`
  const method = 'post'
  const response = await fetch(
    url,
    {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ preview }),
    }
  )
  const status = response.status
  if (status === 401) {
    return {
      error: true
    }
  }
  return await response.json()
}
