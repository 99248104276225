import { combineReducers } from 'redux'
import { produce } from 'immer'
import moment from 'moment'

const messages = (state = [ ], { type, key, messageType }) =>
  type === 'addMessage'
  ? [ ...state, { key, messageType } ]
  : type === 'resetMessage'
  ? []
  : state

const displayFlags = produce( (state = {}, { toggleDisplay: flag }) => {
  if (flag !== undefined) { state[flag] = !state[flag] }
  return state
})

const requests = produce( (state = {}, { request, requestDone }) => {
  if (request !== undefined) {
    // console.log('requesting', request, 'status', state[request])
    if (state[request] === undefined || state[request] === true) {
      // console.log('no record of completion, setting as pending')
      state[request] = true
    } else {
      let recordedTime = state[request]
      let now = moment()
      let then = moment(recordedTime)
      let toleranceInMillis = parseInt(process.env.REACT_APP_THROTTLE) * 2
      let elapsedMillis = now.diff(then, 'milliseconds')
      if (elapsedMillis > toleranceInMillis) {
        // console.log('tolerance exceeding, setting as pending')
        state[request] = true
      }
      // else console.log('ignoring elapsedMillis', elapsedMillis)
    }
  }
  if (requestDone !== undefined) {
    state[requestDone] = moment().valueOf()
    // console.log('Recording last completion, moment utc', state[requestDone])
  }
  return state
})

const url = (state = null, { type, url }) =>
  type === 'setUrl' ? url : state

const selectors = (state = null, { type, selectors }) =>
  type === 'setSelectors' ? selectors : state

const pathname = (state = null, { type, pathname }) =>
  type === 'navigation' ? pathname : state

const token = (state = null, { type, token }) =>
  type === 'setToken'
  ? token
  : type === 'logout' ? null : state

const selection = produce( (state = {}, { type, pathname, entity, id, response: { data } = {} }) => {
  if (type == 'setState') {
      return JSON.parse(data.attributes.data).ui.selection
  }
  if (!(type === 'select' || (type === 'navigation' && pathname.startsWith('/project/')))) {
    return state
  }
  let newEntity
  let newId
  if (type === 'navigation' && pathname.startsWith('/project/')) {
    newEntity = 'project'
    newId = pathname.match(/^\/project\/(\d+)/)[1]
    if (state['view']) {
      delete(state['view'])
    }
  } else {
    newEntity = entity
    newId = id
  }
  state[newEntity] = newId
})

const attrSelection = produce( (state = [], { type, id, attributeType, textValue, variantName, brickId, nonable, viewId, response: { data } = {} }) => {
  if (type == 'setState') {
      return JSON.parse(data.attributes.data).ui.attrSelection
  }
  if (!(type === 'toggleAttr' || type === 'setAttr' || type === 'noneAttr' || type === 'setTextAttr' || type === 'navigation'))
    return state

  if (type === 'navigation') {
    return []
  }
  if ((type === 'toggleAttr' || type === 'setAttr')) {
    //Check if an attribute from that brick is already there
    let brick_index = state.find(
      ([attrId, attrType, textValue, variantName, brId, vId]) => attrType === attributeType && brId === brickId && vId === viewId
    )

    if (nonable) {

      //Check if exact attribute is already there
      let index = state.find(
        ([attrId, attrType, textValue, variantName, brId, vId]) => attrId === id && attrType === attributeType && brId === brickId && vId === viewId
      )
      if (index) {
        return state.filter(
          ([attrId, attrType, textValue, variantName, brId, vId]) =>  !(attrId === index[0] && attrType === attributeType && brId === brickId && vId === viewId)
        )
      } else {
        if (brick_index) {
          state.splice(state.indexOf(brick_index), 1)
        }
        state.unshift( [id, attributeType, textValue, variantName, brickId, viewId] )
      }
    } else {
      if (brick_index) {
        state.splice(state.indexOf(brick_index), 1)
      }
      state.unshift( [id, attributeType, textValue, variantName, brickId, viewId] )
    }
  }
  if ((type === 'noneAttr')) {
    return state.filter(
      ([attrId, attrType, textValue, variantName, brId, viewId]) => brId !== brickId
    )
  }

  if ((type === 'setTextAttr')) {
    let textBricks = state.find(
      ([attrId, attrType,  textValue, variantName, brId, viewId]) => brId === brickId
    )

    if (textBricks && textBricks[1] == "textAttribute") {
      textBricks[2] = textValue
      textBricks[5] = viewId
      state.unshift(textBricks)
    }
  }
  // return state
})

export default combineReducers({
  messages,
  displayFlags,
  requests,
  url,
  selectors,
  pathname,
  token,
  selection,
  attrSelection,
})
