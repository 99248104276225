import React from 'react'
import { connect } from 'react-redux'

import { getFCCost } from 'select'

import './Cost.css'

const Cost = connect(
  state => ({
    cost: getFCCost(state),
  })
)(({ cost }) => {
  return (
    <span id="cost">{cost.display}</span>
  )
})

export default Cost
