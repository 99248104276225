import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import copy from 'copy'
// import { useFetchData } from 'hooks'
import { setLinkedAttributeValue, setActiveBrickId, toggleAttr, noneAttr, setTextAttr } from 'actions'
import { getActiveView, getOrderedViewBricks, getOrderedBricks, getAttributes, getTextSelections, getAttrSelection, getLinkedAttributes, getViewAttributes } from 'select'
// import { shouldDisplay } from 'select/ui'

import InnerLoader from 'scenes/app/InnerLoader'

import './BrickList.css'

export const BrickList = connect(
  state => ({
    // Selectors
    view: getActiveView(state),
    bricks: getOrderedBricks(state),
    viewBricks: getOrderedViewBricks(state),
    attributes: getAttributes(state),
    textSelections: getTextSelections(state),
    selection: getAttrSelection(state),
    linkedAttributes: getLinkedAttributes(state),
    viewAttributes: getViewAttributes(state),
  }),
  dispatch => bindActionCreators({
    // Actions
    setActiveBrickId,
    toggleAttr,
    noneAttr,
    setTextAttr,
    setLinkedAttributeValue,
  }, dispatch)
)(({
  // Props
  view,
  brickIndex,
  bricks,
  attributes,
  textSelections,
  selection,
  viewBricks,
  toggleAttr,
  noneAttr,
  setTextAttr,
  linkedAttributes,
  viewAttributes,
  setLinkedAttributeValue,
}) => {

  const [bricksList, setBricksList] = useState()
  const [viewBricksList, setViewBricksList] = useState()
  const [text, setText] = useState()
  const [defaultAttrs, setDefaultAttrs] = useState()


  useEffect(() => {
    setBricksList(bricks)
    setViewBricksList(viewBricks)
    setText([])
    setDefaultAttrs(false)
  }, [bricks, viewBricks])

  useEffect(() => {
    defaultSelection()
  }, [defaultAttrs])

  const returnCustomizationBrick = (brick, index, attributes, viewBricksList) => {
    return <div className="brick">
      <div className="col___gb col___gb11 brick-details">
        <p className="brick-title" >{index} - {brick.attributes.name}</p>
        <div className="swatches">
            {attributes && attributes.map((attribute, index) => (attribute && attribute[brick.id] && attribute[brick.id].map((attr, index) => (
              <img key={index}
                className={selection.find(([atrId]) => atrId == attr.id) ? "swatch selected" : "swatch" }
                src={attr.attributes.swatch}
                onClick={() => {
                  toggleAttr(attr.id, 'customizationAttribute', null, null, brick.id, brick.attributes.nonable)
                }} />
            ))))}
            {(brick && brick.attributes && brick.attributes.nonable) &&
              <div className="none_swatch" onClick={() => { noneAttr(brick.id) }}></div>
            }
        </div>
      </div>
    </div>
  }

  const returnSymbolBrick = (brick, index, attributes, viewBricksList) => {
    return <div className="brick">
      <div className="brick-details">
        <p className="brick-title" >{index} - {brick.attributes.name}</p>
        <div className="swatches">
            {attributes && attributes.map((attribute, index) => (attribute && attribute[brick.id] && attribute[brick.id].map((attr, index) => (

              <img key={index}
                className={selection.find(([atrId]) => atrId == attr.id) ? "swatch selected" : "swatch" }
                src={attr.attributes.swatch}
                onClick={() => {
                  toggleAttr(attr.id, 'symbolAttribute', null, null, brick.id, brick.attributes.nonable)
                }} />
            ))))}
            {(brick && brick.attributes && brick.attributes.nonable) &&
              <div className="none_swatch" onClick={() => { noneAttr(brick.id)} }></div>
            }
        </div>
      </div>
    </div>
  }



  let customerPlaceholder = false
  if (typeof distinctionAddTextPlaceholder != "undefined") {
    // eslint-disable-next-line
    customerPlaceholder = distinctionAddTextPlaceholder
  }

  const returnTextBrick = (brick, index, attributes, viewBricksList) => {
    return <div className="brick">
      <div className="brick-details">
        <p className="brick-title">{index} - {brick.attributes.name}</p>
        <input
          className="brick-input text-input"
          type="text"
          placeholder={ customerPlaceholder ? customerPlaceholder : copy.text_placeholder }
          value={text[brick.id] || ""}
          maxLength={brick.attributes.characterLimit}
          onChange={ev => {
            let texts = text
            texts[brick.id] = ev.target.value
            setText(texts)
            setTextAttr(ev.target.value, brick.id, view.id)
          }}
        />
        <div className="swatches">
            {attributes && attributes.map((attribute, index) => (attribute && attribute[brick.id] && attribute[brick.id].map((attr, index) => (
              <img key={index}
                className={selection.find(([atrId]) => atrId == attr.id) ? "swatch selected" : "swatch" }
                src={attr.attributes.swatch}
                onClick={() => {
                  if (attr.attributes.isLinked) {
                    //toggle selected Attribute
                    toggleAttr(attr.id, 'textAttribute', textSelections.filter(textSelection => textSelection.id == brick.id)[0] ? textSelections.filter(textSelection => textSelections.filter(textSelection => textSelection.id == brick.id))[0].value : "", null, brick.id)
                    //Get all linked attributes
                    linkedAttributes.forEach((attribute, index) => {
                      if (attribute.type != "textAttribute") {
                          //Change blendingValue of related ViewAttribute
                          setLinkedAttributeValue( Object.values(viewAttributes.filter(viewAttribute => viewAttribute.attributes.attributableId.toString() === attribute.id))[0].id, Object.values(viewAttributes.filter(viewAttribute => viewAttribute.attributes.attributableId.toString() === attr.id))[0].attributes.typefaceColor)
                      }
                    })
                  } else {
                    toggleAttr(attr.id, 'textAttribute', textSelections.filter(textSelection => textSelection.id == brick.id)[0] ? textSelections.filter(textSelection => textSelections.filter(textSelection => textSelection.id == brick.id))[0].value : "", null, brick.id)
                  }
                }} />
            ))))}
            {(brick && brick.attributes && brick.attributes.nonable) &&
              <div className="none_swatch" onClick={() => { noneAttr(brick.id)} }></div>
            }
        </div>
      </div>
    </div>
  }

  const returnBrick = (brick, index, attributes, viewBricksList) => {
    switch(brick.attributes.brickTypeId) {
      case 1:
        return returnCustomizationBrick(brick, index, attributes, viewBricksList)
      case 2:
        return returnTextBrick(brick, index, attributes, viewBricksList)
      case 3:
        return returnSymbolBrick(brick, index, attributes, viewBricksList)
      default:
        return false
    }
  }
  const defaultSelection = () => {
    bricksList && bricksList.map((brick, index) => {
      switch(brick.attributes.brickTypeId) {
        case 1:
            if (brick.attributes.defaultable && attributes && attributes.map((attribute, index) => (attribute && attribute[brick.id] && attribute[brick.id][0]))) {
                attributes.map((attribute, index) => (attribute && attribute[brick.id] && attribute[brick.id][0] && toggleAttr(attribute[brick.id][0].id, 'customizationAttribute', null, null, brick.id)))
            }
            break
        case 2:
          if (brick.attributes.defaultable && attributes && attributes.map((attribute, index) => (attribute && attribute[brick.id] && attribute[brick.id][0]))) {
              attributes.map((attribute, index) => (attribute && attribute[brick.id] && attribute[brick.id][0] && toggleAttr(attribute[brick.id][0].id, 'textAttribute', textSelections.filter(textSelection => textSelection.id == brick.id)[0] ? textSelections.filter(textSelection => textSelections.filter(textSelection => textSelection.id == brick.id))[0].value : "", null, brick.id)))
          }
          break
        case 3:
          if (brick.attributes.defaultable && attributes && attributes.map((attribute, index) => (attribute && attribute[brick.id] && attribute[brick.id][0]))) {
              attributes.map((attribute, index) => (attribute && attribute[brick.id] && attribute[brick.id][0] && toggleAttr(attribute[brick.id][0].id, 'symbolAttribute', null, null, brick.id)))
          }
          break
        default:
      }
    })
  }

  return !bricks
    ? <InnerLoader />
    : <div className="BrickList">
        <div className="bricklist-list">
            {(brickIndex === undefined && bricksList) && bricksList.map((brick, index) => (
              <div key={brick.id} >
                    { returnBrick(brick, index, attributes, viewBricksList) }
              </div>
            ))}
            { (brickIndex !== undefined && bricksList && bricksList[brickIndex]) &&
              <div key={bricksList[brickIndex].id} >
                    { returnBrick(bricksList[brickIndex], brickIndex, attributes, viewBricksList) }

              </div>
            }
          </div>
  </div>
})

export default BrickList
