export const toggleDisplay = flag => ({ type: null, toggleDisplay: flag })

export const signup = (email, password, organization_name, provider, woocommerce, terms_agreement, receive_newsletter, options = {}) => ({
  type: 'signup',
  request: 'signup',
  email,
  password,
  organization_name,
  provider,
  woocommerce,
  terms_agreement,
  receive_newsletter,
  ...options,
})

export const requestPasswordReset = email => ({
  type: 'requestPasswordReset',
  email,
})

export const resetPassword = (password, recover_token) => ({
  type: 'resetPassword',
  password,
  recover_token,
})
export const resetTopMessage = () => ({
  type: 'resetMessage'
})

export const login = (email, password) => ({
  type: 'login',
  email,
  password,
  request: 'login',
})

export const logout = () => ({
  type: 'logout',
})

export const setSidebar = sidebar => ({
  type: 'setSidebar',
  sidebar,
})

export const setUrl = (url) =>
  ({ type: 'setUrl', url })

export const setPreview = (preview) =>
  ({ type: 'setPreview', preview })

export const setBackgroundImage = (backgroundImage) =>
    ({ type: 'setBackgroundImage', backgroundImage })

export const setSelectors = (selectors) =>
  ({ type: 'setSelectors', selectors })

export const setActiveProjectId = (id = null, options = {}) =>
  ({ type: 'select', entity: 'project', id, ...options })

export const saveProject = (project, options = {}) => ({ type: 'saveProject', request: 'projectSave', project, ...options})

export const deleteProject = (id, options = {}) => ({ type: 'deleteData', entity: 'project', id, request: 'projectDelete', ...options })

export const duplicateProject = (id, options = {}) => ({ type: 'duplicateProject', id, request: 'projectDuplicate', ...options })

export const setActiveViewId = (id = null, options = {}) => ({ type: 'select', entity: 'view', id, ...options })

export const saveView = view => ({ type: 'saveView', request: 'viewSave', view })

export const deleteView = (id) => ({ type: 'deleteData', entity: 'view', id, request: 'viewDelete' })

export const setActiveBrickId = (id = null, options = {}) =>
  ({ type: 'select', entity: 'brick', id, ...options })

export const saveViewBricksOrder = (viewBricks) => ({ type: 'saveViewBricksOrder', viewBricks })

export const saveViewBrick = (viewBrick) => ({ type: 'saveViewBrick', viewBrick })

export const saveBrick = brick => ({ type: 'saveBrick', request: 'brickSave', brick })

export const deleteBrick = (id) => ({ type: 'deleteData', entity: 'brick', id, request: 'brickDelete' })

export const setActiveOrganizationId = id =>
  ({ type: 'select', entity: 'organization', id })

export const setActiveAttributeId = (id = null, options = {}) =>
  ({ type: 'select', entity: 'attribute', id, ...options })

export const saveAttribute = (brickTypeId, attribute, id) => {
  switch(brickTypeId) {
    case 1:
      return saveCustomizationAttribute(attribute, id)
    case 2:
      return saveTextAttribute(attribute, id)
   case 3:
      return saveSymbolAttribute(attribute, id)
   default:
      return []
  }
}

export const saveCustomizationAttribute = (attribute, id) => ({ type: 'saveCustomizationAttribute', request: 'customizationAttributeSave', attribute, id })
export const saveTextAttribute = (attribute, id) => ({ type: 'saveTextAttribute', request: 'textAttributeSave', attribute, id })
export const saveSymbolAttribute = (attribute, id) => ({ type: 'saveSymbolAttribute', request: 'symbolAttributeSave', attribute, id })

export const deleteAttribute = (brickTypeId, id) => {
  switch(brickTypeId) {
    case 1:
      return deleteCustomizationAttribute(id)
    case 2:
      return deleteTextAttribute(id)
   case 3:
      return deleteSymbolAttribute(id)
   default:
     return []
  }
}

export const deleteCustomizationAttribute = id => ({ type: 'deleteData', entity: 'customizationAttribute', id, request: 'customizationAttributeDelete' })
export const deleteTextAttribute = id => ({ type: 'deleteData', entity: 'textAttribute', id, request: 'textAttributeDelete' })
export const deleteSymbolAttribute = id => ({ type: 'deleteData', entity: 'symbolAttribute', id, request: 'symbolAttributeDelete' })

export const duplicateAttribute = (brickTypeId, id, options = {}) => {
  switch(brickTypeId) {
    case 1:
      return duplicateCustomizationAttribute(id, options)
    case 2:
      return duplicateTextAttribute(id, options)
    case 3:
      return duplicateSymbolAttribute(id, options)
   default:
      return []
  }
}

export const duplicateCustomizationAttribute = (id, options = {}) => ({ type: 'duplicateCustomizationAttribute', request: 'customizationAttributeDuplicate', id })
export const duplicateTextAttribute = (id, options = {}) => ({ type: 'duplicateTextAttribute', request: 'textAttributeDuplicate', id })
export const duplicateSymbolAttribute = (id, options = {}) => ({ type: 'duplicateSymbolAttribute', request: 'symbolAttributeDuplicate', id })

export const setActiveViewAttributeId = (id = null, options = {}) =>
  ({ type: 'select', entity: 'viewAttribute', id, ...options })

export const saveViewAttribute = viewAttribute => ({ type: 'saveViewAttribute', request: 'viewAttributeSave', viewAttribute })

export const deleteViewAttribute = (id) => ({ type: 'deleteData', entity: 'viewAttribute', id, request: 'viewAttributeDelete' })

export const saveOrganizationDetails = organizationDetails => ({ type: 'saveOrganizationDetails', request: 'organizationSave', organizationDetails })
export const publishOrganization = () => ({ type: 'publishOrganization', request: 'organizationPublish' })

export const savePreview = preview => ({ type: 'savePreview', request: 'previewSave', preview })
export const getPreview = {}


export const saveUserSettings = user => ({
  type: 'saveUserSettings',
  user,
})

export const fetchUser = (slug) => ({ type: 'fetchData', slug, request: 'user' })
export const fetchUsers = () => ({ type: 'fetchData', request: 'users' })
export const fetchOrganizations = () => ({ type: 'fetchData', request: 'organizations' })
export const fetchProducts = (slug) => ({ type: 'fetchData', slug, request: 'products' })
export const fetchOrganizationDetails = slug => ({ type: 'fetchData', slug, request: 'organization' })
export const fetchProject = id => ({ type: 'fetchData', id, request: 'project' })

export const toggleAttr = (id, attributeType, textValue, variantName, brickId, nonable) => ({ type: 'toggleAttr', id, attributeType, textValue, variantName, brickId, nonable })
export const noneAttr = (brickId) => ({ type: 'noneAttr', brickId})
export const noneAllAttrs = () => ({ type: 'noneAllAttrs'})
export const setTextAttr = (textValue, brickId, viewId) => ({ type: 'setTextAttr', textValue, brickId, viewId})

export const setLinkedAttributeValue = (viewAttributeId, blendingValue) =>
  ({ type: 'setLinkedAttributeValue', entity: 'viewAttribute', viewAttributeId, blendingValue })
