import React from 'react'
import { connect } from 'react-redux'

import { getProjectViews, getActiveView } from 'select'

import './ConsumerViewSelect.css';

const ConsumerViewSelect = ({ views, view, setActiveViewId, resetTopMessage, dispatch }) =>  !views
  ? null // Loading
  : <div className="ViewSelect">
  { views.map(({ id, name }) =>
    <button className="ViewSelector" key={id}
      onClick={() => { dispatch({ type: 'select', entity: 'view', id })} }
    >
      {view && id === view.id &&
        <div className="ViewSelected"></div>
      }
    </button>
  ) }
</div>

export default connect(
  state => ({
    views: getProjectViews(state),
    view: getActiveView(state),
  })
)(ConsumerViewSelect)
